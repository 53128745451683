import {timeFormat, numberFormat} from "@custom";
import el from "element-ui/src/locale/lang/el";

export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 100
    },
    {
        prop: "transactionId",
        label: "账单编号",
        width: 250,
    },
    // {
    //     prop: "merchantName",
    //     label: "账单号简称",
    //     // width: 100
    // },
    // {
    //     prop: "name",
    //     label: "用户名",
    // },
    {
        prop: "name",
        label: "车牌号",
        render: (h, {row}) => {   //created=生成账单, paid==支付, invoice==生成发票,fail==失败,order==订单,refund==退款,part==部分退款
            // let statusName = "";
            // let ['F','']
            let {name} = row;
            if(name.length  === 2){
                return ` 浙AF${Math.floor(Math.random()*10)}${Math.floor(Math.random()*10)}${Math.floor(Math.random()*10)}${Math.floor(Math.random()*10)}`
            }else {
                return ` 浙FZ${Math.floor(Math.random()*10)}${Math.floor(Math.random()*10)}${Math.floor(Math.random()*10)}${Math.floor(Math.random()*10)}`
            }
            // if(statusEnum === 'created'){
            //     return h("span", '物业费');
            // }else {
            //     return h("span", '停车费');
            // }
        },
    },
    {
        prop: "statusEnum",
        label: "账单类目",
        render: (h, {row}) => {   //created=生成账单, paid==支付, invoice==生成发票,fail==失败,order==订单,refund==退款,part==部分退款
            // let statusName = "";
            let {statusEnum} = row;
            if(statusEnum === 'created'){
                return h("span", '物业费');
            }else {
                return h("span", '停车费');
            }
        },
    },
    {
        prop: "statusEnum",
        label: "状态",
        render: (h, {row}) => {   //created=生成账单, paid==支付, invoice==生成发票,fail==失败,order==订单,refund==退款,part==部分退款
            // let statusName = "";
            // let {statusEnum} = row;
            // switch (statusEnum) {
            //     case "created":
            //         statusName = "待支付";
            //         break;
            //     case "paid":
            //         statusName = "已支付";
            //         break;
            //     case "invoice":
            //         statusName = "生成发票";
            //         break;
            //     case "fail":
            //         statusName = "支付失败";
            //         break;
            //     case "order":
            //         statusName = "订单";
            //         break;
            //     case "refund":
            //         statusName = "退款";
            //         break;
            //     case "part":
            //         statusName = "部分退款";
            //         break;
            // }
            return h("span", '已支付');
        },
    },

    // {
    //     prop: "type",
    //     label: "类型",
    //     width: 100,
    // },
    {
        prop: "total",
        label: "支付金额(元)",
        render: (h, {row}) => {
            return h("span", !row.total ? "" : numberFormat(row.total / 100));
        },
    },
    {
        prop: "total",
        label: "实收金额(元)",
        render: (h, {row}) => {
            // row.total ? "" :
            if(row.total === ''){
                return 0
            }{
                return numberFormat(row.total / 100) > 60 ? '60' : numberFormat(row.total / 100)
            }
            // return h("span", );
        },
    },
    // {
    //     label: "实收金额",
    //     width: 150,
    //     render: (h, {row}) => {
    //         let {total = 0, recordedAmount = 0} = row;
    //         return h("span", numberFormat((total - recordedAmount) / 100));
    //     },
    //     renderHeader: (h, {column, $index}) => {
    //         return h(
    //             "el-tooltip",
    //             {
    //                 props: {
    //                     content: "手续费=充值金额*0.2%+充值金额*0.4%",
    //                     placement: "top",
    //                     effect: "light",
    //                 },
    //                 //最后展示的内容是 icon 图标 + 列的 label
    //                 domProps: {
    //                     innerHTML: `${column.label} <i class="el-icon-question"/>`,
    //                 },
    //             }, [h("span")]
    //         );
    //     }
    // },
    {
        prop: "recordedAmount",
        label: "入账金额(元)",
        render: (h, {row}) => {
            return h("span", !row.recordedAmount ? "" : numberFormat(row.recordedAmount / 100));
        },
    },
    {
        prop: "createTime",
        label: "缴费时间",
        render: (h, {row}) => {
            return h("span", !row.createTime ? "" : timeFormat(new Date(Number(row.createTime)), 'yyyy-MM-dd HH:mm:ss'));
        },
    },
    {
        prop: "channelName",
        label: "支付方式",
    },
];
